import React from 'react';
import { Navigate, useLocation, useMatch } from 'react-router';
import cn from 'classnames';

import { analytics, isPreview } from 'src/utils';
import { routes } from 'src/constants';
import { ChartType } from 'src/types/chart';
import { getChart } from 'src/store/chart/actions';
import { selectChart } from 'src/store/chart/selectors';
import { selectUI } from 'src/store/ui/selectors';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { getEditorChoice } from 'src/store/editor-choice/actions';
import Cooladata, { Props as CooladataProps } from 'src/components/common/Cooladata';
import TopHeader from 'src/components/common/TopHeader';

import Head from './Head';
import Banner from './Banner';
import Header from './Header';
import Content from './Content';
import Outbrain from './Outbrain';

import css from './Main.module.scss';

type Props = {
  className?: string;
};

const Main = (props: Props) => {
  const { className = '' } = props;
  const dispatch = useAppDispatch();
  const chartData = useAppSelector(selectChart);
  const chart = (useMatch(routes.MAIN)?.params.chart as ChartType) || null;
  const ui = useAppSelector(selectUI);
  const location = useLocation();
  const isPreviewMode = isPreview(location.search);
  const cooladataPageId: CooladataProps['pageId'] = chart
    ? (
        {
          [ChartType.TOP100]: 'HP',
          [ChartType.ISRAEL]: 'Israeli',
          [ChartType.INTERNATIONAL]: 'International',
          [ChartType.NEW_SONGS]: 'New_Songs',
        } as const
      )[chart]
    : 'HP';

  const [chartType, setChartType] = React.useState<ChartType>(chart || ChartType.TOP100);
  const onChartChange = (type: ChartType) => {
    setChartType(type);
  };

  React.useEffect(() => {
    const vpH = document.documentElement.clientHeight;
    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight,
    );
    const scrollPoints = [
      { progress: 0.25, isPassed: false },
      { progress: 0.5, isPassed: false },
      { progress: 0.75, isPassed: false },
      { progress: 1, isPassed: false },
    ];

    const onWindowScroll = () => {
      const scrollProgress = window.scrollY / (scrollHeight - vpH);

      scrollPoints.forEach((scrollPoint) => {
        if (scrollProgress >= scrollPoint.progress && scrollPoint.isPassed === false) {
          analytics.gtag.event('Scroll', { CUSTOM_PARAMETER: `${scrollPoint.progress * 100}` });
          scrollPoint.isPassed = true;
        }
      });
    };

    window.addEventListener('scroll', onWindowScroll);

    return () => {
      window.removeEventListener('scroll', onWindowScroll);
    };
  }, []);

  React.useEffect(() => {
    if (!chartData.title) {
      dispatch(getChart({ isPreviewMode }));
      dispatch(getEditorChoice());
    }
  }, []);

  if (ui.isChartDataFetching) return <div className={css.fakePreloader}></div>;

  if ([null, ChartType.ISRAEL, ChartType.INTERNATIONAL, ChartType.NEW_SONGS].includes(chart) === false) {
    return <Navigate to={routes.ROOT} />;
  }

  return (
    <>
      <Head />
      <div className={cn(css.main, className)}>
        <Banner />
        <TopHeader />
        <Header chartTab={chartType} onChartChange={onChartChange} />
        <Content chartTab={chartType} />
      </div>
      <Cooladata pageId={cooladataPageId} />
      <Outbrain />
    </>
  );
};

export default Main;
