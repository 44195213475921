import React from 'react';
import cn from 'classnames';

import { useDFP, type Params as DFPParams } from 'src/hooks/dfp';
import css from './Banner.module.scss';

function Banner() {
  const [params, setParams] = React.useState<DFPParams>({ slotPath: '', slotSize: [0, 0] });
  const dfp = useDFP(params);
  // const dfp = { isClosed: false, isRendered: true, minWidth: 960, minHeight: 330 };
  const bannerLabel = 'פרסומת';

  React.useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 1023px)').matches;

    setParams({
      containerId: isMobile ? 'div-gpt-ad-1678117736535-0' : 'div-gpt-ad-1678118324982-0',
      slotPath: isMobile ? '/242748299/mako/hitlist/maavaron' : '/242748299/mako/hitlist/PRIME',
      slotSize: isMobile ? [320, 410] : [970, 330],
      closable: true,
    });
  }, []);

  return dfp.isClosed === false ? (
    <div className={cn(css.bannerWrap, dfp.isRendered && css.rendered)}>
      <p className={css.label}>{bannerLabel}</p>
      <div className={css.banner}>
        <div id={params.containerId} style={{ minWidth: `${dfp.minWidth}px`, minHeight: `${dfp.minHeight}px` }}></div>
      </div>
    </div>
  ) : null;
}

export default Banner;
