import React from 'react';
import cn from 'classnames';

import { useDFP, type Params as DFPParams } from 'src/hooks/dfp';
import css from './InnerBanner.module.scss';

interface Props {
  className?: string;
  index: '10' | '20';
}

export function InnerBanner(props: Props) {
  const { index, className = '' } = props;
  const [params, setParams] = React.useState<DFPParams>({ slotPath: '', slotSize: [0, 0] });
  const dfp = useDFP(params);
  // const dfp = { isClosed: false, isRendered: true, minWidth: 994, minHeight: 137 };
  const title = 'פרסומת';

  React.useEffect(() => {
    const controller = new AbortController();
    const onResize = () => {
      const isMobile = window.matchMedia('(max-width: 1024px)').matches;
      const desktopParams: Record<Props['index'], DFPParams> = {
        '10': {
          containerId: 'div-gpt-ad-1707744029101-0',
          slotPath: '/242748299/mako/hitlist/JAMBO1_970',
          slotSize: [970, 90],
          targeting: ['FORMAT', ['JAMBO1_970']],
        },
        '20': {
          containerId: 'div-gpt-ad-1707743900909-0',
          slotPath: '/242748299/mako/hitlist/COAST2COAST1_970',
          slotSize: [970, 90],
          targeting: ['FORMAT', ['COAST2COAST1_970']],
        },
      };
      const mobileParams: Record<Props['index'], DFPParams> = {
        '10': {
          containerId: 'div-gpt-ad-1707744584329-0',
          slotPath: '/242748299/mako/hitlist/mobile_monster1_300x250',
          slotSize: [300, 250],
          targeting: ['FORMAT', ['MOBILE_MONSTER1_300x250']],
        },
        '20': {
          containerId: 'div-gpt-ad-1707744671181-0',
          slotPath: '/242748299/mako/hitlist/mobile_monster2_300x250',
          slotSize: [300, 250],
          targeting: ['FORMAT', ['MOBILE_MONSTER2_300x250']],
        },
      };

      if (!params.containerId) {
        setParams(isMobile ? mobileParams[index] : desktopParams[index]);
      }
    };

    onResize();
    window.addEventListener('resize', onResize, { signal: controller.signal });

    return () => controller.abort();
  }, [params.containerId]);

  return dfp.isClosed === false ? (
    <div className={cn(css.innerBanner, dfp.isRendered && css.rendered, className)}>
      <div className={css.bannerContainer}>
        <div className={css.title}>
          <p>{title}</p>
        </div>
        <div className={css.banner}>
          <div id={params.containerId} style={{ minWidth: `${dfp.minWidth}px`, minHeight: `${dfp.minHeight}px` }}></div>
        </div>
      </div>
    </div>
  ) : null;
}

export default InnerBanner;
