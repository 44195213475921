import React from 'react';
import cn from 'classnames';
import { NavLink, Link, useMatch } from 'react-router-dom';
import { routes } from 'src/constants';

import { useAppSelector } from 'src/hooks/store';
import { selectCurrentYearChart } from 'src/store/chart-of-the-year/selectors';
import { analytics } from 'src/utils';

import Shares from 'src/components/common/Shares';

import makoLogo from 'src/assets/images/top-header/makoLogo.svg';
import hitListLogo from 'src/assets/images/top-header/hitlistLogo.png';
import menuSrc from 'src/assets/images/header/menu.svg';
import closeSrc from 'src/assets/images/header/close.svg';
import makoLogoSmall from './mako-logo-small.png';

import css from './TopHeader.module.scss';

type Props = {
  className?: string;
  fixed?: boolean;
};

export const TopHeader = (props: Props) => {
  const { className = '', fixed = false } = props;
  const [isOpenMenu, setIsOpenMenu] = React.useState<boolean>(false);
  const isWeeklyChartPage = [useMatch(routes.ROOT), useMatch(routes.MAIN)].some(Boolean);
  const year = useAppSelector(selectCurrentYearChart);

  const clickMenuBtn = () => {
    setIsOpenMenu(true);
  };

  const clickCloseMenuBtn = () => {
    setIsOpenMenu(false);
  };

  const clickOnLink = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    const { name } = e.currentTarget.dataset;
    analytics.gtag.event('Chart_Type', { CUSTOM_PARAMETER: `${name}` });
  };

  const renderMenuBtns = () => {
    if (isOpenMenu) {
      return (
        <div className={css.closeMenuBtn} onClick={clickCloseMenuBtn}>
          <img src={closeSrc} className={css.icon} alt="" />
        </div>
      );
    }
    return (
      <div className={css.menuBtn} onClick={clickMenuBtn}>
        <img src={menuSrc} className={css.icon} alt="" />
      </div>
    );
  };

  return (
    <header className={cn(css.headerWrapper, className, isWeeklyChartPage && css.weeklyChartPage, fixed && css.fixed)}>
      {isWeeklyChartPage ? (
        <a href={process.env.WEBSITE_URL} className={css.hitlistLogo}>
          <img src={hitListLogo} alt="hitlist-logo" />
        </a>
      ) : (
        <div className={css.hitlistLogo}>
          <img src={hitListLogo} alt="hitlist-logo" />
        </div>
      )}
      <div className={css.menuBtns}>{renderMenuBtns()}</div>
      <nav className={cn(css.navgation, isOpenMenu && css.open)}>
        <NavLink to={routes.ROOT} className={css.navLink} data-name="המצעד השבועי" onClick={clickOnLink}>
          {({ isActive }) => {
            return <span className={isActive ? css.active : ''}>המצעד השבועי</span>;
          }}
        </NavLink>
        <NavLink
          to={routes.ANNUAL_CHART.replace(':year?', `${year}`)}
          className={css.navLink}
          data-name="המצעד השנתי"
          onClick={clickOnLink}
        >
          {({ isActive }) => {
            return <span className={isActive ? css.active : ''}>המצעד השנתי</span>;
          }}
        </NavLink>
        <Link
          to="https://www.mako.co.il/music-news/local/Article-5042af7d07ff681027.htm"
          className={css.navLink}
          target="_blank"
          rel="noreferrer"
        >
          <span>אודות</span>
        </Link>
      </nav>
      {isWeeklyChartPage ? (
        <div className={css.makoLogo}>
          <img src={makoLogo} alt="mako-logo" />
        </div>
      ) : (
        <Link to={routes.ANNUAL_CHART.replace(':year?', `${year}`)} className={css.makoLogo}>
          <img src={makoLogo} alt="mako-logo" />
        </Link>
      )}
      <div className={css.sharesWrap}>
        <Shares />
      </div>
      <div className={css.makoLogoSmall}>
        <img src={makoLogoSmall} alt="mako-logo" />
      </div>
    </header>
  );
};

export default TopHeader;
