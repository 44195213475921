import React from 'react';
import { useParams, useLocation, Outlet } from 'react-router-dom';

import cn from 'classnames';

import { isPreview } from 'src/utils';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { selectChartOfTheYear } from 'src/store/chart-of-the-year/selectors';
import { selectUI } from 'src/store/ui/selectors';
import { getChart } from 'src/store/chart-of-the-year/actions';
import TopHeader from 'src/components/common/TopHeader';

import Head from './Head';
import TopSection from './TopSection';
import SideBanner from './SideBanner';
import Outbrain from '../Main/Outbrain';
import Banner from '../Main/Banner';
import { Cooladata } from 'src/components/common';

import deskBg from 'src/assets/images/content/annual_bg_desk.jpg';
import mobBg from 'src/assets/images/content/annual_bg_mob.jpg';

import css from './AnnualChart.module.scss';

type Props = {
  className?: string;
};

const AnnualChart = (props: Props) => {
  const { className = '' } = props;

  const location = useLocation();
  const isPreviewMode = isPreview(location.search);
  const { year, categorySlug } = useParams();

  const dispatch = useAppDispatch();
  const chart = useAppSelector(selectChartOfTheYear);
  const ui = useAppSelector(selectUI);
  const [isMobile, setIsMobile] = React.useState(false);
  const [isOutbrainVisible, setIsOutbrainVisible] = React.useState(false);
  const cooladataPageId = categorySlug ? 'content' : 'HP';

  React.useEffect(() => {
    const controller = new AbortController();
    const onResize = () => {
      setIsMobile(window.innerWidth <= 799);
    };
    window.addEventListener('resize', onResize, { signal: controller.signal });
    onResize();

    const outbrainWrapEl = document.querySelector('.outbrain-wrap');
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsOutbrainVisible(entry.isIntersecting);
      },
      { threshold: 0 },
    );

    if (outbrainWrapEl) {
      observer.observe(outbrainWrapEl);
    }

    return () => {
      controller.abort();
      observer.disconnect();
    };
  }, []);

  React.useEffect(() => {
    /**
     * fetch annual chart if there is no data in redux,
     * it can be in 2 cases:
     * - when user was on weekly chart and click on "go to annual chart" button
     * - when user on the annual chart page and select another annual chart in the dropdown
     */

    if (chart.year !== Number(year ?? '1900')) {
      dispatch(getChart({ isPreviewMode, year: Number(year ?? '1900') }));
    }
  }, [year]);

  if (ui.isChartDataFetching) return <div className={css.fakePreloader}></div>;

  return (
    <>
      <div className={cn(css.annualChart, className)}>
        <Head />
        <Banner />
        <TopHeader />
        <TopSection />
        <div className={css.content} style={{ backgroundImage: `url('${isMobile ? mobBg : deskBg}')` }}>
          <SideBanner />
          <Outlet />
          <div className={cn(css.rabbiCredits, isOutbrainVisible && css.withOutbrain)}>
            <div className={css.rabbiCreditsContent}>
              <p>Developed by</p>
              <a href="https://www.rabbi.co.il" title="רבי אינטראקטיב">
                Rabbi Agency
              </a>
            </div>
          </div>
        </div>
      </div>
      <Cooladata pageId={cooladataPageId} year={year || ''} category={categorySlug || ''} />
      <Outbrain />
    </>
  );
};

export default AnnualChart;
